import { Survey } from './Survey';
import { map } from 'rxjs/operators';
import { Candidate, CandidateId } from '../domain/Candidate';
import { HttpClient } from '@angular/common/http';
import { Question, QuestionId, Answer, AnswerId } from '../domain/Question';
import { CandidateQuestionare, CandidateAnswer } from '../domain/CandidateQuestionare';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SurveyLoaderStaticFileService = /** @class */ (function () {
    function SurveyLoaderStaticFileService(http) {
        this.http = http;
    }
    SurveyLoaderStaticFileService.prototype.loadSurvey = function (surveyId) {
        var _this = this;
        var baseUrl = document.getElementsByTagName('base')[0].href;
        var url = "/assets/data/" + surveyId + "/details.json";
        url = baseUrl + url;
        return this.http.get(url)
            .pipe(map(function (data) { return _this.parseSurvey(surveyId, data); }))
            .toPromise();
    };
    SurveyLoaderStaticFileService.prototype.parseSurvey = function (surveyId, data) {
        var _this = this;
        return new Survey(surveyId, data.title, data.properties, data.questions.map(function (item) { return _this.parseQuestion(item); }), data.candidates.map(function (item) { return _this.parseCandidate(item); }), data.candidates.map(function (item) { return _this.parseCandidateQuestionare(item); }));
    };
    SurveyLoaderStaticFileService.prototype.parseQuestion = function (data) {
        var _this = this;
        return new Question(QuestionId.of(data.id), data.text, data.description, data.answers.map(function (item) { return _this.parseAnswer(item); }));
    };
    SurveyLoaderStaticFileService.prototype.parseAnswer = function (data) {
        return new Answer(AnswerId.of(data.id), data.text, data.description, (data.nullable) ? data.nullable : false);
    };
    SurveyLoaderStaticFileService.prototype.parseCandidate = function (data) {
        return new Candidate(CandidateId.of(data.id), data.name, data.description, data.image);
    };
    SurveyLoaderStaticFileService.prototype.parseCandidateQuestionare = function (data) {
        var _this = this;
        return new CandidateQuestionare(CandidateId.of(data.id), data.answers.map(function (item) { return _this.parseCandidateAnswer(item); }));
    };
    SurveyLoaderStaticFileService.prototype.parseCandidateAnswer = function (data) {
        return new CandidateAnswer(QuestionId.of(data.questionId), AnswerId.of(data.answerId), data.score);
    };
    SurveyLoaderStaticFileService.ngInjectableDef = i0.defineInjectable({ factory: function SurveyLoaderStaticFileService_Factory() { return new SurveyLoaderStaticFileService(i0.inject(i1.HttpClient)); }, token: SurveyLoaderStaticFileService, providedIn: "root" });
    return SurveyLoaderStaticFileService;
}());
export { SurveyLoaderStaticFileService };
