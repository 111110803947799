import { Questionare } from '../domain/Questionare';
import { UserAnswersSnapshot } from './user-answers/user-answers-repository.service';
import { LocalStorageRepository } from './user-answers/localstorage-repository.service';
import { SurveyLoaderStaticFileService } from './survey.loader.staticfile.service';
import * as i0 from "@angular/core";
import * as i1 from "./survey.loader.staticfile.service";
import * as i2 from "./user-answers/localstorage-repository.service";
var SurveyService = /** @class */ (function () {
    function SurveyService(surveyLoader, userAnswersRepository) {
        this.surveyLoader = surveyLoader;
        this.userAnswersRepository = userAnswersRepository;
    }
    SurveyService.prototype.setSurveyContext = function (surveyId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.surveyLoader.loadSurvey(surveyId)
                .then(function (survey) {
                _this.survey = survey;
                _this.questionare = new Questionare(survey.questions, survey.candidateQuestionare);
                resolve(survey);
            })
                .catch(function (e) {
                console.error(e);
                reject();
            });
        });
    };
    SurveyService.prototype.getSurvey = function () {
        return this.survey;
    };
    SurveyService.prototype.getQuestionare = function () {
        return this.questionare;
    };
    SurveyService.prototype.saveAnswers = function () {
        var snapshot = new UserAnswersSnapshot(this.questionare.answers);
        this.userAnswersRepository.saveUserAnswers(this.survey.id, snapshot);
    };
    SurveyService.prototype.loadAnswers = function () {
        var that = this;
        this.userAnswersRepository.loadUserAnswers(this.survey.id)
            .then(function (snapshot) {
            if (snapshot) {
                snapshot.userAnswers.forEach(function (a) { return that.questionare.userAnswer(a); });
            }
        });
    };
    SurveyService.ngInjectableDef = i0.defineInjectable({ factory: function SurveyService_Factory() { return new SurveyService(i0.inject(i1.SurveyLoaderStaticFileService), i0.inject(i2.LocalStorageRepository)); }, token: SurveyService, providedIn: "root" });
    return SurveyService;
}());
export { SurveyService };
