var UserAnswer = /** @class */ (function () {
    function UserAnswer(questionId, answerId) {
        this.questionId = questionId;
        this.answerId = answerId;
        this._nullable = false;
    }
    UserAnswer.prototype.markAsNullable = function () {
        this._nullable = true;
    };
    Object.defineProperty(UserAnswer.prototype, "isNullable", {
        get: function () {
            return this._nullable;
        },
        enumerable: true,
        configurable: true
    });
    return UserAnswer;
}());
export { UserAnswer };
export function answerForQuestion(questionId) {
    return function (a) { return a.questionId.equals(questionId); };
}
