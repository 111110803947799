<section class="final-report">
<mat-card>
  <mat-card-title>
    Wyniki
  </mat-card-title>

  <mat-card-content *ngIf="presentInfoUnansweredQuestions">
    <h2 class="mat-title">Nie odpowiedziano na wszystkie pytania</h2>

    <p>
      Nie udzielono odpowiedzi na wszystkie pytania. Zachęcamy do uzupełnienia wszystkich odpowiedzi.
    </p>

    <button mat-raised-button color="primary"
      class="survey-report-show-unanswered"
      (click)="doUnansweredQuestionsIgnore()">
      Pokaż raport mimo to</button>
  </mat-card-content>

  <mat-card-content *ngIf="presentReport">
    <!--<p>
      <span
        class="avatar-label avatar-label-xl">
          <img
          src="assets/images/user-avatar.svg"
          class="avatar" />
        Ty
      </span>
    </p>-->

    <h2 class="mat-title">Twoim poglądom najbardziej odpowiadają</h2>

    <ol class="matches">
      <li *ngFor="let match of matches; let i = index" class="my-3">
        <span class="bar" [style.width.%]="match.match * 100 / matchesMaxScore"></span>
        <span class="bar-content">
          <div class="row align-items-center">
            <div class="col col-auto">
              <img
                src="{{ candidatesById[match.candidateId.value].image }}"
                class="avatar" />
            </div>
            <div class="col avatar-label">
              {{ candidatesById[match.candidateId.value].name }}
              <span class="percentage">{{ match.match * 100 | number: '0.0-2' }}%</span>
            </div>
          </div>
        </span>
      </li>
    </ol>

    <p class="text-center">
      <button mat-raised-button color="primary"
        class="survey-nav-take-again my-3"
        (click)="doRestart()"
        routerLink="/{{ survey.id }}/q/{{ survey.questions[0].id.value }}"
        >Odpowiedz jeszcze raz</button>
    </p>

    <h2 class="mat-headline">Szczegółowe wyniki</h2>

    <div [innerHtml]="survey.properties.textCompletion">
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="presentReport">
<div *ngFor="let answerSummary of answersSummary">
  <mat-card class="my-4">
  <h2 class="mat-title survey-question">{{ answerSummary.question.text }}</h2>

  <!--<div *ngIf="!answerSummary.userAnswered">
    Nie udzieliłeś odpowiedzi.
  </div>-->
  
  <div *ngFor="let answer of answerSummary.answers">
    <mat-radio-button
      checked="{{ answer.userAnswer }}">
      {{ answer.answer.text }}
    </mat-radio-button>
    
    <div class="sameVote row">
      <div class="col-12 col-m-12">
        <span *ngIf="!answer.userAnswer && !answer.candidatesAnswers.length"
          class="sameVoteLabel">Nikt nie podziela tego poglądu</span>
        <span *ngIf="answer.userAnswer || answer.candidatesAnswers.length"
          class="sameVoteLabel">Takie poglądy mają:</span>
      </div>

      <div class="col-12 col-m-12">
        <span *ngIf="answer.userAnswer"
          class="avatar-label mx-1 my-1">
            <img
            src="assets/images/user-avatar.svg"
            class="avatar" />
          Ty
        </span>
        <span *ngFor="let candidateAnswer of answer.candidatesAnswers"
          class="avatar-label mx-1 my-1">
          <img
            src="{{ candidatesById[candidateAnswer.candidateId.value].image }}"
            class="avatar" />
          {{ candidatesById[candidateAnswer.candidateId.value].name }}
        </span>
      </div>
    </div>
  </div>
  </mat-card>
</div>
</div>
</section>