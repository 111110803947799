var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericId } from "./Id";
var Candidate = /** @class */ (function () {
    function Candidate(id, name, description, image) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.image = image;
    }
    return Candidate;
}());
export { Candidate };
var CandidateId = /** @class */ (function (_super) {
    __extends(CandidateId, _super);
    function CandidateId() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CandidateId.of = function (value) {
        return new CandidateId(value);
    };
    return CandidateId;
}(GenericId));
export { CandidateId };
export function candidateById(candidateId) {
    return function (q) { return q.id.equals(candidateId); };
}
