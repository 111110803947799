import { answerForQuestion } from './UserAnswer';
var Questionare = /** @class */ (function () {
    function Questionare(questions, candidatesQuestionaries) {
        this.questions = questions;
        this.candidatesQuestionaries = candidatesQuestionaries;
        this.userAnswers = [];
    }
    Questionare.prototype.reset = function () {
        this.userAnswers = [];
    };
    Questionare.prototype.userAnswer = function (userAnswer) {
        if (!this.checkIfQuestionExists(userAnswer.questionId)) {
            throw "Given question does not exists.";
        }
        if (!this.checkIfAnswerExists(userAnswer.questionId, userAnswer.answerId)) {
            throw "Given answer does not exists.";
        }
        var selectedAnswer = this.questions
            .find(function (q) { return q.id.equals(userAnswer.questionId); })
            .answers
            .find(function (a) { return a.id.equals(userAnswer.answerId); });
        if (selectedAnswer.isNullableAnswer) {
            userAnswer.markAsNullable();
        }
        var foundAnswerIdx = this.userAnswers.findIndex(answerForQuestion(userAnswer.questionId));
        if (foundAnswerIdx != -1) {
            this.userAnswers[foundAnswerIdx] = userAnswer;
        }
        else {
            this.userAnswers.push(userAnswer);
        }
    };
    Object.defineProperty(Questionare.prototype, "answers", {
        get: function () {
            return Array.from(this.userAnswers.values());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Questionare.prototype, "answeredQuestions", {
        get: function () {
            return this.userAnswers
                .filter(function (a) { return !a.isNullable; })
                .map(function (a) { return a.questionId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Questionare.prototype, "finished", {
        get: function () {
            return this.userAnswers.length == this.questions.length;
        },
        enumerable: true,
        configurable: true
    });
    Questionare.prototype.matchCandidates = function (algo) {
        return algo.match(this.candidatesQuestionaries, Array.from(this.userAnswers.values()));
    };
    Questionare.prototype.checkIfQuestionExists = function (questionId) {
        return this.questions.find(function (q) { return q.id.equals(questionId); }) !== undefined;
    };
    Questionare.prototype.checkIfAnswerExists = function (questionId, answerId) {
        return this.questions
            .find(function (q) { return q.id.equals(questionId); })
            .answers
            .find(function (a) { return a.id.equals(answerId); }) !== undefined;
    };
    return Questionare;
}());
export { Questionare };
