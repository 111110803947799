import { UserAnswer } from "../../domain/UserAnswer";
import { UserAnswersSnapshot } from "./user-answers-repository.service";
import { QuestionId, AnswerId } from "../../domain/Question";
import * as i0 from "@angular/core";
var LocalStorageRepository = /** @class */ (function () {
    function LocalStorageRepository( /*private storage: WindowLocalStorage*/) {
        this.storage = null;
    }
    LocalStorageRepository.prototype.saveUserAnswers = function (surveyId, data) {
        var that = this;
        return new Promise(function (resolve, reject) {
            var payload = that.toJson(data);
            var key = "survey-" + surveyId;
            window.localStorage.setItem(key, payload);
            resolve(true);
        });
    };
    LocalStorageRepository.prototype.loadUserAnswers = function (surveyId) {
        var that = this;
        return new Promise(function (resolve, reject) {
            var key = "survey-" + surveyId;
            var data = window.localStorage.getItem(key);
            if (null === data) {
                resolve(null);
            }
            else {
                resolve(that.fromJson(data));
            }
        });
    };
    LocalStorageRepository.prototype.toJson = function (data) {
        var result = {};
        result.userAnswers = data.userAnswers.map(function (a) {
            return {
                questionId: a.questionId.value,
                answerId: a.answerId.value,
            };
        });
        return JSON.stringify(result);
    };
    LocalStorageRepository.prototype.fromJson = function (data) {
        var payload = JSON.parse(data);
        var userAnswers = payload.userAnswers.map(function (a) {
            return new UserAnswer(QuestionId.of(a.questionId), AnswerId.of(a.answerId));
        });
        return new UserAnswersSnapshot(userAnswers);
    };
    LocalStorageRepository.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorageRepository_Factory() { return new LocalStorageRepository(); }, token: LocalStorageRepository, providedIn: "root" });
    return LocalStorageRepository;
}());
export { LocalStorageRepository };
