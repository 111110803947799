var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericId } from "./Id";
var Question = /** @class */ (function () {
    function Question(id, text, description, answers) {
        this.id = id;
        this.text = text;
        this.description = description;
        this.answers = answers;
    }
    return Question;
}());
export { Question };
var QuestionId = /** @class */ (function (_super) {
    __extends(QuestionId, _super);
    function QuestionId() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QuestionId.of = function (value) {
        return new QuestionId(value);
    };
    return QuestionId;
}(GenericId));
export { QuestionId };
var Answer = /** @class */ (function () {
    function Answer(id, text, description, isNullableAnswer) {
        this.id = id;
        this.text = text;
        this.description = description;
        this.isNullableAnswer = isNullableAnswer;
    }
    return Answer;
}());
export { Answer };
var AnswerId = /** @class */ (function (_super) {
    __extends(AnswerId, _super);
    function AnswerId() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AnswerId.of = function (value) {
        return new AnswerId(value);
    };
    return AnswerId;
}(GenericId));
export { AnswerId };
export function questionById(questionId) {
    return function (q) { return q.id.equals(questionId); };
}
