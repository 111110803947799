import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyService } from '../survey.service';
import { Title } from '@angular/platform-browser';
var SurveyComponent = /** @class */ (function () {
    function SurveyComponent(route, surveyService, titleService) {
        this.route = route;
        this.surveyService = surveyService;
        this.titleService = titleService;
    }
    SurveyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.url.subscribe(function (urlSegment) {
            _this.isQuetionOpened = _this.route.firstChild !== null;
        });
        this.route.params.subscribe(function (params) {
            var surveyId = params.surveyId;
            _this.doInit(surveyId);
        });
    };
    SurveyComponent.prototype.doInit = function (surveyId) {
        var _this = this;
        this.surveyService.setSurveyContext(surveyId)
            .then(function (survey) {
            _this.survey = survey;
            _this.questionare = _this.surveyService.getQuestionare();
            _this.surveyService.loadAnswers();
            _this.titleService.setTitle(survey.title);
        });
    };
    SurveyComponent.prototype.doStart = function () {
        this.surveyService.getQuestionare().reset();
    };
    SurveyComponent.prototype.isQuestionAnswered = function (quetionId) {
        return this.questionare.answeredQuestions.find(function (id) { return id.equals(quetionId); }) !== undefined;
    };
    Object.defineProperty(SurveyComponent.prototype, "isIntro", {
        get: function () {
            return !this.isQuetionOpened;
        },
        enumerable: true,
        configurable: true
    });
    return SurveyComponent;
}());
export { SurveyComponent };
