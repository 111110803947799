import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Question, questionById, QuestionId, AnswerId } from 'src/domain/Question';
import { SurveyService } from '../survey.service';
import { UserAnswer, answerForQuestion } from '../../domain/UserAnswer';
var SurveyQuestionComponent = /** @class */ (function () {
    function SurveyQuestionComponent(route, surveyService) {
        this.route = route;
        this.surveyService = surveyService;
    }
    SurveyQuestionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            var questionId = params.questionId;
            _this.doInit(questionId);
        });
    };
    SurveyQuestionComponent.prototype.doInit = function (questionId) {
        this.survey = this.surveyService.getSurvey();
        var question = this.survey
            .questions
            .find(questionById(QuestionId.of(questionId)));
        this.question = question;
        this.questionare = this.surveyService.getQuestionare();
        this.selectedAnswerId = this.getSelectedAnswer(question);
    };
    SurveyQuestionComponent.prototype.getSelectedAnswer = function (question) {
        var answer = this.questionare.answers
            .find(answerForQuestion(question.id));
        return (answer === undefined) ? null : answer.answerId;
    };
    SurveyQuestionComponent.prototype.doAnswerPickup = function (answer) {
        this.selectedAnswerId = answer;
    };
    SurveyQuestionComponent.prototype.doAnswer = function () {
        this.questionare.userAnswer(new UserAnswer(this.question.id, this.selectedAnswerId));
        this.surveyService.saveAnswers();
    };
    Object.defineProperty(SurveyQuestionComponent.prototype, "nextQuestion", {
        get: function () {
            var data = this.survey.questions;
            var idx = data.indexOf(this.question);
            if (idx >= 0 && idx < data.length - 1) {
                return data[idx + 1];
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyQuestionComponent.prototype, "prevQuestion", {
        get: function () {
            var data = this.survey.questions;
            var idx = data.indexOf(this.question);
            if (idx > 0 && idx < data.length) {
                return data[idx - 1];
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return SurveyQuestionComponent;
}());
export { SurveyQuestionComponent };
