<section *ngIf="isIntro" class="intro text-center">
  <div class="container">
    <div *ngIf="!survey" class="text-center">
      <mat-progress-spinner mode="indeterminate" class="block-center"></mat-progress-spinner>
    </div>

    <div *ngIf="survey" class="text-white">
      <div [innerHtml]="survey.properties.introHeadingHtml"></div>
      
      <hr />

      <div class="text-faded mb-5" [innerHtml]="survey.properties.textIntroduction">
      </div>

      <div class="lets-start text-center">
        <button mat-raised-button color="primary"
          class="mat-big-button"
          *ngIf="!isQuetionOpened"
          (click)="doStart()"
          routerLink="/{{ survey.id }}/q/{{ survey.questions[0].id.value }}">Zaczynamy</button>
      </div>
      
      <footer class="mt-5">
        Survey software by <a href="http://athlan.pl" target="_blank">Piotr Pelczar</a>.
      </footer>
    </div>
  </div>
</section>

<section *ngIf="!isIntro" class="survey">
  <div class="container">
    <div *ngIf="survey">
      <div class="surveyTitle" [innerHtml]="survey.properties.titleHeadingHtml"></div>

      <p>
      <mat-chip-list *ngIf="isQuetionOpened">
        <mat-chip *ngFor="let question of survey.questions; let i = index"
          
          routerLink="/{{ survey.id }}/q/{{ question.id.value }}"
          routerLinkActive="mat-chip-selected"
          [ngClass]="{ 'question-answered': isQuestionAnswered(question.id) }"
          >
          {{ i + 1 }}
        </mat-chip>
        <mat-chip
          routerLink="/{{ survey.id }}/r"
          routerLinkActive="mat-chip-selected">
          Wynik
        </mat-chip>
      </mat-chip-list>
      </p>
      
      <router-outlet></router-outlet>
    </div>

    <footer>
      Survey software by <a href="http://athlan.pl" target="_blank">Piotr Pelczar</a>.
    </footer>
  </div>

</section>
