<mat-card>
    <mat-card-content>

      <h2 class="mat-title survey-question">{{ question.text }}</h2>
      
      <p *ngFor="let answer of question.answers">
        <mat-radio-button name="answer" value="{{ answer.id }}"
          (change)="doAnswerPickup(answer.id)"
          [checked]="answer.id.equals(selectedAnswerId)">
          {{ answer.text }}
        </mat-radio-button>
      </p>
  
    </mat-card-content>

    <mat-card-content>
      
      <span *ngIf="nextQuestion">
          <button mat-raised-button color="primary"
            class="survey-nav-answer"
            (click)="doAnswer()"
            routerLink="/{{ survey.id }}/q/{{ nextQuestion.id.value }}"
            [disabled]="!selectedAnswerId">Dalej</button>
  
          <button mat-button
            class="survey-nav-skip"
            routerLink="/{{ survey.id }}/q/{{ nextQuestion.id.value }}">Pomiń</button>
      </span>
      <span *ngIf="!nextQuestion">
        <button mat-raised-button color="primary"
          class="survey-nav-answer"
          (click)="doAnswer()"
          routerLink="/{{ survey.id }}/r"
          [disabled]="!selectedAnswerId">Dalej</button>
  
        <button mat-button
          class="survey-nav-skip"
          routerLink="/{{ survey.id }}/r">Pomiń</button>
      </span>
  
      <span *ngIf="prevQuestion">
        <button mat-button
          class="survey-nav-previous"
          routerLink="/{{ survey.id }}/q/{{ prevQuestion.id.value }}">Poprzednie</button>
      </span>
      
      <span *ngIf="!prevQuestion">
        <button mat-button
          class="survey-nav-previous"
          routerLink="/{{ survey.id }}">Wróć</button>
      </span>
    </mat-card-content>
    
</mat-card>