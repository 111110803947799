import { OnInit } from '@angular/core';
import { SurveyService } from '../survey.service';
import { WeightedAnswersAlgo } from '../../domain/nearestcandidate/algo/WeightedAnswersAlgo';
import { sortCandidateMatchByResult } from '../../domain/nearestcandidate/algo/AlgoUtils';
import { answerForQuestion } from '../../domain/UserAnswer';
import { answerForQuestion as candidateAnswerForQuestion, answerAs } from '../../domain/CandidateQuestionare';
var SurveyResultsComponent = /** @class */ (function () {
    function SurveyResultsComponent(surveyService) {
        this.surveyService = surveyService;
        this.hasUnansweredQuestionsIgnored = false;
    }
    SurveyResultsComponent.prototype.ngOnInit = function () {
        this.survey = this.surveyService.getSurvey();
        this.questionare = this.surveyService.getQuestionare();
        var matches = this.questionare.matchCandidates(new WeightedAnswersAlgo());
        this.matches = matches.sort(sortCandidateMatchByResult);
        this.matchesMaxScore = Math.max.apply(Math, matches.map(function (m) { return m.match; }));
    };
    Object.defineProperty(SurveyResultsComponent.prototype, "hasUnansweredQuestions", {
        get: function () {
            return this.questionare.answeredQuestions.length < this.survey.questions.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyResultsComponent.prototype, "presentReport", {
        get: function () {
            return !this.presentInfoUnansweredQuestions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyResultsComponent.prototype, "presentInfoUnansweredQuestions", {
        get: function () {
            var displayInfo = this.survey.properties['reportInfoAboutUnansweredQuestions'];
            return this.hasUnansweredQuestions
                && displayInfo !== false
                && !this.hasUnansweredQuestionsIgnored;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyResultsComponent.prototype, "candidatesById", {
        get: function () {
            var results = {};
            this.survey.candidates.forEach(function (c) {
                results[c.id.value] = c;
            });
            return results;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyResultsComponent.prototype, "answersSummary", {
        get: function () {
            var _this = this;
            var questions = this.survey.questions;
            return questions.map(function (question) {
                var userAnswer = _this.questionare.answers.find(answerForQuestion(question.id));
                var userAnswered = userAnswer !== undefined;
                var answers = question.answers.map(function (answer) {
                    var candidatesAnswers = [];
                    _this.survey.candidateQuestionare.forEach(function (questionare) {
                        var candidateAnswer = questionare.answers
                            .filter(candidateAnswerForQuestion(question.id))
                            .find(answerAs(answer.id));
                        if (candidateAnswer) {
                            candidatesAnswers.push({
                                candidateId: questionare.candidateId,
                                answer: candidateAnswer
                            });
                        }
                    });
                    return {
                        answer: answer,
                        userAnswer: (userAnswered) ? userAnswer.answerId.equals(answer.id) : false,
                        candidatesAnswers: candidatesAnswers,
                    };
                });
                return {
                    question: question,
                    answers: answers,
                    userAnswered: userAnswered
                };
            });
        },
        enumerable: true,
        configurable: true
    });
    SurveyResultsComponent.prototype.doUnansweredQuestionsIgnore = function () {
        this.hasUnansweredQuestionsIgnored = true;
    };
    SurveyResultsComponent.prototype.doRestart = function () {
        this.surveyService.getQuestionare().reset();
        this.surveyService.saveAnswers();
    };
    return SurveyResultsComponent;
}());
export { SurveyResultsComponent };
